<template>
    <v-card>
        <v-card-title class="text-h6 mb-3">
            {{ order.tracking_id }}
            <v-icon @click="downloadStamp" color="primary" class="cursor-pointer"
                v-if="checkPermission('super_admin')">mdi-download</v-icon>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close">
                {{ $t('labels.cancel') }}
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-simple-table fixed-header class="table-padding-2-no-top mb-5">
                <template v-slot:default>
                    <thead class="v-data-table-header">
                        <tr>
                            <th role="columnheader" class="text-center">Điện thoại</th>
                            <th role="columnheader" class="text-center">Tên</th>
                            <th role="columnheader" class="text-center">+ Tích điểm</th>
                            <th role="columnheader" class="text-center">Thời gian</th>
                            <th role="columnheader" class="text-center">Nhân viên</th>
                            <th role="columnheader" class="text-center">Phân loại</th>
                            <th role="columnheader" class="text-center">POS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center">
                            <td>{{ order.buyer_phone }}</td>
                            <td>{{ order.buyer_name }}</td>
                            <td>{{ formatNumber(order.accrue_point) }}</td>
                            <td>{{ formatDateTime(order.created_at) }}</td>
                            <td>{{ order.employee_name }}</td>
                            <td :class="{ 'error--text': order.type === 2 }">{{ order.type_text }}</td>
                            <td>{{ order.pos_code }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-simple-table fixed-header class="table-padding-2-no-top mb-5">
                <template v-slot:default>
                    <thead class="v-data-table-header">
                        <tr>
                            <th role="columnheader" class="text-center">Giá trị hóa đơn</th>
                            <th role="columnheader" class="text-center">KM. Hóa đơn</th>
                            <th role="columnheader" class="text-center">Mã giảm giá</th>
                            <th role="columnheader" class="text-center">Thanh toán</th>
                            <th role="columnheader" class="text-center">Chuyển Khoản</th>
                            <th role="columnheader" class="text-center">Tiền mặt</th>
                            <th role="columnheader" class="text-center">Trả thẻ</th>
                            <th role="columnheader" class="text-center">Dùng điểm</th>
                            <th role="columnheader" class="text-center">Trả lại</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center">
                            <td>{{ formatNumber(order.bill_price) }}</td>
                            <td>{{ formatNumber(order.bill_promotion) }}</td>
                            <td>{{ formatNumber(order.voucher_promotion) }}</td>
                            <td>{{ formatNumber(order.payment_price) }}</td>
                            <td>{{ formatNumber(order.payment_transfer) }}</td>
                            <td>{{ formatNumber(order.payment_cash) }}</td>
                            <td>{{ formatNumber(order.payment_card) }}</td>
                            <td>{{ formatNumber(order.payment_point) }}</td>
                            <td>{{ formatNumber(order.payment_return) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-simple-table class="table-padding-2-no-top mb-5">
                <thead class="v-data-table-header">
                    <tr>
                        <th role="columnheader" class="text-center">Ảnh</th>
                        <th role="columnheader" class="text-center">Barcode</th>
                        <th role="columnheader" class="text-center error--text">Sản phẩm</th>
                        <th role="columnheader" class="text-center">Đặc tả</th>
                        <th role="columnheader" class="text-center">Size</th>
                        <th role="columnheader" class="text-center">Số lượng</th>
                        <th role="columnheader" class="text-center">Nguyên giá</th>
                        <th role="columnheader" class="text-center">Giá khuyến mại</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" v-for="(item, index) in promotionGoodsItems"
                        :key="`pi_${item.id_goods}_${index}`">
                        <td>
                            <ImageViewer width="60px" height="auto" v-if="item.url_images" :url="item.url_images" />
                        </td>
                        <td>{{ item.customer_goods_barcode }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.size }}</td>
                        <td>{{ item.quantity }}</td>
                        <td><span class="font-weight-medium">{{ formatNumber(item.base_price) }}</span></td>
                        <td><span class="font-weight-medium success--text">{{ formatNumber(item.bill_price) }}</span>
                        </td>
                    </tr>
                    <tr class="text-center" v-for="(item, index) in goodsItems" :key="`i_${item.id_goods}_${index}`">
                        <td>
                            <ImageViewer width="60px" height="auto" v-if="item.url_images" :url="item.url_images" />
                        </td>
                        <td>{{ item.customer_goods_barcode }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.size }}</td>
                        <td>{{ item.quantity }}</td>
                        <td><span class="font-weight-medium">{{ formatNumber(item.base_price) }}</span></td>
                        <td></td>
                    </tr>
                </tbody>
            </v-simple-table>

            <v-simple-table class="table-padding-2-no-top mb-5">
                <thead class="v-data-table-header">
                    <tr>
                        <th role="columnheader" class="text-center error--text">Combo</th>
                        <th role="columnheader" class="text-center">Số lượng</th>
                        <!--<th role="columnheader" class="text-center">Khả dụng</th>-->
                        <th role="columnheader" class="text-center">Nguyên giá</th>
                        <th role="columnheader" class="text-center">Giá khuyến mại</th>
                        <th role="columnheader" class="text-center">Barcode</th>
                        <th role="columnheader" class="text-center">Sản phẩm</th>
                        <th role="columnheader" class="text-center">Đặc tả</th>
                        <th role="columnheader" class="text-center">Size</th>
                        <th role="columnheader" class="text-center">Ảnh</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" v-for="(item, index) in promotionComboItems" :key="`ci_${index}`">
                        <td>{{ item.code }}</td>
                        <td>{{ item.quantity }}</td>
                        <!--<td></td>-->
                        <td><span class="font-weight-medium">{{ formatNumber(item.total_base_price) }}</span></td>
                        <td><span class="font-weight-medium success--text">{{ formatNumber(item.total_bill_price)
                                }}</span>
                        </td>
                        <td>
                            <div v-for="(cItem, cIndex) in item.items" :key="`cc_${index}_${cIndex}`">
                                {{ cItem.customer_goods_barcode }} * {{ cItem.combo_item_quantity }}
                            </div>
                        </td>
                        <td>
                            <div v-for="(cItem, cIndex) in item.items" :key="`cc_${index}_${cIndex}`">
                                {{ cItem.name }}
                            </div>
                        </td>
                        <td>
                            <div v-for="(cItem, cIndex) in item.items" :key="`cc_${index}_${cIndex}`">
                                {{ cItem.description }}
                            </div>
                        </td>
                        <td>
                            <div v-for="(cItem, cIndex) in item.items" :key="`cc_${index}_${cIndex}`">
                                {{ cItem.size }}
                            </div>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </v-simple-table>

            <v-simple-table class="table-padding-2-no-top mb-5" v-if="exGoodsItems.length > 0">
                <thead class="v-data-table-header">
                    <tr>
                        <th role="columnheader" class="text-center error--text">Mã đơn đổi trả</th>
                        <th role="columnheader" class="text-center">Ảnh</th>
                        <th role="columnheader" class="text-center">UID</th>
                        <th role="columnheader" class="text-center">Barcode</th>
                        <th role="columnheader" class="text-center">Sản phẩm</th>
                        <th role="columnheader" class="text-center">Đặc tả</th>
                        <th role="columnheader" class="text-center">Size</th>
                        <th role="columnheader" class="text-center error--text">Giá mua</th>
                        <th role="columnheader" class="text-center error--text">Ưu đãi đã hưởng</th>
                        <th role="columnheader" class="text-center error--text">Giảm trừ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" v-for="(item, index) in exGoodsItems" :key="`i_${item.id_goods}_${index}`">
                        <td>{{ item.tracking_id }}</td>
                        <td>
                            <ImageViewer width="60px" height="auto" v-if="item.url_images" :url="item.url_images" />
                        </td>
                        <td>{{ item.sku_uid }}</td>
                        <td>{{ item.customer_goods_barcode }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.size }}</td>
                        <td>{{ formatNumber(-item.bill_price) }}</td>
                        <td>{{ formatNumber(-item.extra_promotion) }}</td>
                        <td>{{ formatNumber(-item.payment_price) }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>

        <OrderStamp v-if="printOrder && printOrder.id" @onPrinted="onPrinted" :order="printOrder" />
    </v-card>
</template>

<script>

import { httpClient } from "@/libs/http";

export default {
    name: "OrderDetail",
    components: {
        ImageViewer: () => import('@/components/common/ImageViewer'),
        OrderStamp: () => import('@/components/pos/OrderStamp'),
    },
    props: {
        order: {
            type: Object,
            default: () => {
            }
        }
    },
    data: () => ({
        items: [],
        printOrder: {}
    }),
    computed: {
        goodsItems() {
            const baseItems = [...this.items].filter(item => (!item.is_exchanged || (item.is_exchanged === 1 && item.payment_price > 0)) && !item.id_promotion_product && !item.id_promotion_combo)
            const goodsItems = []
            for (let i = 0; i < baseItems.length; i++) {
                const item = baseItems[i]
                const checkItem = goodsItems.find(gItem => gItem.id_goods === item.id_goods)
                if (!checkItem) {
                    goodsItems.push({
                        name: item.name,
                        sku: item.sku,
                        customer_goods_barcode: item.customer_goods_barcode,
                        description: item.description,
                        size: item.size,
                        url_images: item.url_images,
                        quantity: 1,
                        base_price: item.base_price,
                        bill_price: item.bill_price,
                        payment_price: item.payment_price,
                    })
                } else {
                    const checkItemIndex = goodsItems.find(gItem => gItem.id_goods === item.id_goods)
                    goodsItems[checkItemIndex].quantity += 1
                    goodsItems[checkItemIndex].base_price += item.base_price
                    goodsItems[checkItemIndex].bill_price += item.bill_price
                    goodsItems[checkItemIndex].payment_price += item.payment_price
                }
            }
            return goodsItems
        },
        exGoodsItems() {
            return [...this.items].filter(item => item.is_exchanged === 1 && item.payment_price < 0).map(item => ({
                ...item,
                sku_uid: item.barcode || `${item.sku}@${item.uid}`
            }))
        },
        promotionGoodsItems() {
            const items = [...this.items].filter(item => !!item.id_promotion_product)
            const goodsItems = []
            for (let i = 0; i < items.length; i++) {
                const item = items[i]
                const checkItem = goodsItems.find(gItem => gItem.id_goods === item.id_goods)
                if (!checkItem) {
                    goodsItems.push({
                        name: item.name,
                        sku: item.sku,
                        customer_goods_barcode: item.customer_goods_barcode,
                        description: item.description,
                        size: item.size,
                        url_images: item.url_images,
                        quantity: 1,
                        base_price: item.base_price,
                        bill_price: item.bill_price,
                        payment_price: item.payment_price,
                    })
                } else {
                    const checkItemIndex = goodsItems.find(gItem => gItem.id_goods === item.id_goods)
                    goodsItems[checkItemIndex].quantity += 1
                    goodsItems[checkItemIndex].base_price += item.base_price
                    goodsItems[checkItemIndex].bill_price += item.bill_price
                    goodsItems[checkItemIndex].payment_price += item.payment_price
                }
            }
            return goodsItems
        },
        promotionComboItems() {
            const items = [...this.items].filter(item => !!item.id_promotion_combo)
            const combos = []
            for (let i = 0; i < items.length; i++) {
                const item = items[i]
                item.items = item.combo_items.split(';').map(cItem => {
                    const [id_goods, quantity] = cItem.split('Ω')
                    const goods = [...this.items].find(g => (g.id_goods === +id_goods))
                    return {
                        sku: goods.sku,
                        customer_goods_barcode: goods.customer_goods_barcode,
                        name: goods.name,
                        description: goods.description,
                        size: goods.size,
                        url_images: goods.url_images,
                        id_goods: +id_goods,
                        combo_item_quantity: +quantity
                    }
                })
                const checkItem = combos.find(cItem => cItem.id_promotion_combo === item.id_promotion_combo)
                if (!checkItem) {
                    combos.push({
                        code: item.promotion_combo_code,
                        id_promotion_combo: item.id_promotion_combo,
                        quantity: 1,
                        total_base_price: item.base_price,
                        total_bill_price: item.bill_price,
                        total_payment_price: item.payment_price,
                        items: item.items
                    })
                } else {
                    const checkItemIndex = combos.findIndex(cItem => cItem.id_promotion_combo === item.id_promotion_combo)
                    combos[checkItemIndex].total_base_price += item.base_price
                    combos[checkItemIndex].total_bill_price += item.bill_price
                    combos[checkItemIndex].total_payment_price += item.payment_price
                    combos[checkItemIndex].quantity = Math.ceil(combos[checkItemIndex].total_bill_price / item.promotion_combo_value)
                }
            }
            return combos
        },
    },
    mounted() {
        this.getItems()
    },
    methods: {
        close() {
            this.$emit('close', true)
        },
        getItems() {
            httpClient.post('/get-order-items', { id: this.order.id }).then(({ data }) => {
                this.items = [...data]
            })
        },
        onPrinted() {
            this.printOrder = {}
        },
        async downloadStamp() {
            try {
                const { data } = await httpClient.post('/get-data-order-bill', { tracking_id: this.order.tracking_id })
                this.printOrder = { ...data }
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
            }
        }
    }
}
</script>

<style scoped></style>